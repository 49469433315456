"use client";
import CtaButton from "@/modules/common/components/cta-button";
import Footer from "@/modules/layout/templates/footer";
import Nav from "@/modules/layout/templates/nav";

// Error boundaries must be Client Components

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center justify-center min-h-96 py-52 sm:min-h-screen px-5 bg-gray-100 text-gray-800">
          <p className="text-2xl mb-8">Oops! An unexpected error occured. </p>
          <CtaButton onClick={() => location.reload()}>Reload</CtaButton>
        </div>
      </body>
    </html>
  );
}
